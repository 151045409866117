import { cilChart } from "@coreui/icons";
import { iconSubset, IconSubset } from "../assets/icons/icon-subset";
import { INavDataExtender } from "./shared/interfaces/navData/INavDataExtender";
import { Roles } from "./shared/models/role.model";
export const navItems: INavDataExtender[] = [
  {
    name: "Home",
    iconComponent: { name: IconSubset.cilHome },
    url: "/home",
    show: true,
    children: [
      {
        name: "Index",
        url: "/home/index",
        icon: "nav-icon-bullet",
      },
    ],
  },
  {
    name: "User Administartion",
    iconComponent: { name: IconSubset.cilPeople },
    url: "/user-administartion",
    show: true,
    children: [
      {
        name: "Users",
        url: "/user-administartion/users/index",
        icon: "nav-icon-bullet",
        role: "UserViewRole",
      },
      {
        name: "Drivers",
        url: "/user-administartion/drivers/index",
        icon: "nav-icon-bullet",
        role: "UserViewRole",
      },
      {
        name: "Roles",
        url: "/user-administartion/roles/index",
        icon: "nav-icon-bullet",
        role: "UserViewRole",
      },
    ],
  },
  {
    name: "Orders",
    iconComponent: { name: IconSubset.cilCart },
    url: "/orders",
    show: true,
    children: [
      {
        name: "Orders",
        url: "/orders/orders",
        icon: "nav-icon-bullet",
        role: ["OrderManageRole", "OrderViewRole"],
      },
      {
        name: "Offline Orders",
        url: "/orders/offline-orders",
        icon: "nav-icon-bullet",
        role: ["OfflineOrdersManageRole", "OfflineOrdersViewRole"],
      },
      {
        name: "Hidden Orders",
        url: "/orders/hidden",
        icon: "nav-icon-bullet",
        role: ["OrderManageRole"],
      },
    ],
  },
  {
    name: "Sales",
    iconComponent: { name: IconSubset.cilChart },
    url: "/sales",
    show: true,
    children: [
      {
        name: "Catalogues",
        url: "/sales/catalogues/index",
        icon: "nav-icon-bullet",
        role: ["CataloguesManageRole"],
      },
    ],
  },
  {
    name: "Warehouse Management",
    iconComponent: { name: IconSubset.cilSquare },
    url: "/Warehouse",
    show: true,
    children: [
      {
        name: "Items Quantites",
        url: "/Warehouse/ItemQuantities",
        icon: "nav-icon-bullet",
        role: ["WarehouseItemQtyViewRole"],
      },
    ],
  },
  {
    name: "Items",
    iconComponent: { name: IconSubset.packageIcon },
    url: "/items",
    show: true,
    children: [
      {
        name: "Items",
        url: "/items/items/index",
        icon: "nav-icon-bullet",
        role: ["ItemViewRole", "ItemManageRole", "ViewCSP", "ManageCSP"],
      },
    ],
  },
  {
    name: "Customers",
    iconComponent: { name: IconSubset.cilPeople },
    url: "/customers",
    show: true,
    children: [
      {
        name: "Customers",
        url: "/customers/index",
        icon: "nav-icon-bullet",
        role: [
          "CustomerViewRole",
          "CustomerManageRole",
          "ViewCSP",
          "ManageCSP",
        ],
      },
    ],
  },
  {
    name: "Jahez",
    iconComponent: { name: IconSubset.cilFile },
    url: "/jahez",
    show: true,
    children: [
      {
        name: "Index",
        url: "/jahez/index",
        icon: "nav-icon-bullet",
        role: ["ViewAspfTransations"],
      },
      {
        name: "Verify Requests",
        url: "/jahez/verify-pending",
        icon: "nav-icon-bullet",
        role: ["ManageVerifyPendingRequests", "ViewVerifyRegistrationRequests"],
      },
      {
        name: "Pending Requests",
        url: "/jahez/pending-requests",
        icon: "nav-icon-bullet",
        role: ["ManagePendingRequests", "ViewApprovalRegistrationRequests"],
      },
      {
        name: "Jahez Customers",
        url: "/jahez/manage-customers",
        icon: "nav-icon-bullet",
        role: ["ManageJahezCustomers", "ViewJahezCustomers"],
      },
      {
        name: "Update ASPF",
        url: "/jahez/update-aspf",
        icon: "nav-icon-bullet",
        role: ["ManageAspf"],
      },
    ],
  },
  {
    name: "Reports",
    iconComponent: { name: IconSubset.cilShortText },
    url: "/reports",
    show: true,
    children: [
      {
        name: "Purchase Orders",
        url: "/reports/purchase-orders",
        icon: "nav-icon-bullet",
        role: ["ViewPurchaseOrderReport"],
      },
      {
        name: "Delivered Orders (Logistics)",
        url: "/reports/delivered-orders-logistics",
        icon: "nav-icon-bullet",
        role: ["ViewDriverAssingmentOrderReport"],
      },
      {
        name: "Po Counts (Logistics)",
        url: "/reports/po-counts-logistics",
        icon: "nav-icon-bullet",
        role: ["ViewDriverAssingmentPoReport"],
      },
    ],
  },

  {
    name: "Item Categorization",
    iconComponent: { name: IconSubset.cilList },
    url: "/item-specifications",
    show: true,
    children: [
      {
        name: "Categories",
        url: "/item-specifications/categories/index",
        icon: "nav-icon-bullet",
        role: ["CategoryViewRole", "CategoryManageRole"],
      },
      {
        name: "Brands",
        url: "/item-specifications/brands/index",
        icon: "nav-icon-bullet",
        role: ["BrandViewRole", "BrandCreateRole"],
      },
      {
        name: "Tags",
        url: "/item-specifications/tags/index",
        icon: "nav-icon-bullet",
        role: ["TagViewRole", "TagManageRole"],
      },
    ],
  },
  {
    name: "SEO",
    iconComponent: { name: IconSubset.cilZoomIn },
    url: "/SEO",
    show: true,
    children: [
      {
        name: "Brands",
        url: "/SEO/brands",
        icon: "nav-icon-bullet",
        role: ["SEOManageBrands"],
      },
    ],
  },
];
